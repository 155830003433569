import { Events } from 'hls.js';
// import { ImgHTMLAttributes } from 'react';
import { SceneComponent, ComponentOutput } from '../SceneComponent';

enum Event {
  Toggle = "toggle",
  Unmute ="unmute"
}

type Inputs = {
  initialState: boolean;
  isToggleTuner : boolean;
};

type Outputs = {
  state: boolean;
  negated: boolean;
} & ComponentOutput;

type Events = {
  [Event.Toggle]: boolean;
};


class ToggleState extends SceneComponent {
  inputs: Inputs = {
    initialState: true,
    isToggleTuner : false,
  };
  outputs = {
    state: true,
    negated: false,
  } as Outputs;

  events = {
    toggle: true,
  } as Events;

  onInit() {
    this.outputs.state = this.inputs.initialState;
    this.outputs.negated = !this.outputs.state;
  }

  onEvent(eventType: string, eventData: unknown) {
    //console.log("inputs.initialState"+ this.inputs.initialState);
    // const BGM = document.getElementsByClassName(".unMuteBGM");
    // const UnmuteBGM = document.querySelector(".unMuteBGM");

    // console.log("UnmuteBGM : " + UnmuteBGM.className);
    // BGM.addEventListener("click", function (event) {
    //   console.log("이미지 클릭  event :" + event);
    // });
    if (eventType === Event.Toggle) {
      console.log("this.outputs.state : " + this.outputs.state);
      this.outputs.state = !this.outputs.state;
      this.outputs.negated = this.outputs.state;
      console.log("클릭클릭  Event.Toggle : "+  Event.Toggle +", eventType : "+  eventType);
      console.log(
        "this.outputs.negated : " +
          this.outputs.negated 
          
      );
    }
  }
}

export const toggleStateType = 'mp.toggleState';
export function makeToggleState() {
  return new ToggleState();
}
