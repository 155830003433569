import React, { Component } from "react";
import {
  Frame,
  GetSDK,
  initComponents,
  SceneComponent,
  ISceneNode,
  ComponentInteractionType,
  orientedBoxType,
  slotType,
  OrientedBox,
  IComponentEventSpy,
  IInteractionEvent,
  sdkKey,
  IPlaneRenderer,
} from "@mp/common";
import { AppState } from "../AppState";
import { SceneLoader } from "../SceneLoader";
import { ItemList } from "./ItemList";
import { ItemDesc } from "src/types";
import { cameraInputType } from "@mp/common/src/sdk-components/Camera";
import { Vector3, Quaternion, Euler, Matrix4 } from "three";
//import { VideoRenderer } from '@mp/common';
import "./style.css";

const SelectedColor = 0xffff00;
const SelectedOpacity = 0.1;
const SelectedLineOpacity = 1.0;
const UnselectedColor = 0xffffff;
const UnselectedOpacity = 0.04;
const UnselectedLineOpacity = 0.4;
var svgRadar: any = null;
var maprotation: any = null;
var isFloorDistinction : any = 0;
var isMap: any = false;
//var isLogo: any = false;

interface Props {
  appState: AppState;
}

interface State {
  slotNode: SlotNode | null;
}

type SlotNode = {
  node: ISceneNode;
  slotComponent: SceneComponent;
  modelComponent: SceneComponent;
  boxComponent: OrientedBox;
};

export class Main extends Component<Props, State> {
  private sdk: any = null;
  private scene: SceneLoader = null;
  private slots: SlotNode[] = [];
  private cameraInput: SceneComponent;
  private src: string;
  private applicationKey: string;
  private modelKey: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      slotNode: null,
    };

    var url;
    
    if (window.location.href.indexOf("localhost")) {
    
      url = new URL(
        "https://premium.360vrmuseum.com/?m=NbA9Vo2qdMV&ss=3&sr=0,3.15&qs=1&play=1&hr=1"
      ); // 로컬 테스트용 url 설정
    } else {
      //console.log("로컬 실행이 아닙니다.");
      const urltmp = window.location.href;
      //=> location.href 로 바꿔야할듯

      url = new URL(urltmp);
    }
    if (
      location.hostname === "localhost" ||
      location.hostname === "127.0.0.1"
    ) {
    }
    //console.log("실행확인!");

    const userAgent = navigator.userAgent.toLowerCase(); // User Agent 문자열을 소문자로 변환하여 저장
    if (userAgent.indexOf("mobile") !== -1) {
      console.log("모바일 디바이스입니다.");
      isMap = false;
      if (
        userAgent.indexOf("iphone") !== -1 ||
        userAgent.indexOf("ipad") !== -1
      ) {
        console.log("iOS 디바이스입니다.");
        isMap = false;

        // iOS 디바이스 처리 로직 작성
      } else if (userAgent.indexOf("android") !== -1) {
        console.log("안드로이드 디바이스입니다.");
        isMap = false;
        // 안드로이드 디바이스 처리 로직 작성
      } else {
        console.log("모바일 디바이스이지만, iOS나 안드로이드가 아닙니다.");
        isMap = false;
        // 기타 모바일 디바이스 처리 로직 작성
      }
    } else if (userAgent.indexOf("tablet") !== -1) {
      isMap = true;
      console.log("태블릿 디바이스입니다.");

      // 태블릿 디바이스 처리 로직 작성
    } else if (
      userAgent.indexOf("macintosh") !== -1 ||
      userAgent.indexOf("mac os x") !== -1
    ) {
      isMap = true;
      console.log("맥북입니다."); // 맥북 디바이스 처리 로직 작성
    } else {
      isMap = true;
      //console.log("데스크톱 디바이스입니다.");
      // 데스크톱 디바이스 처리 로직 작성
    }

    const urltmp = window.location.href;

    url = new URL(urltmp);

    //console.log("urltest : " + url);

    // URLSearchParams 객체
    const urlParams = url.searchParams;

    

    const params = objectFromQuery(); //

    //params.m = params.m || urlParams.get("m");
    params.m = params.m || urlParams.get("m"); // || "NbA9Vo2qdMV"; // url에서 파라미터 값을 받아와서  모델명을  설정
    //console.log("params.m : " + params.m);
    // params.play = params.play || urlParams.get("play");//다 파라미터 값으로 받아온다라..... 흠
    params.hr = params.hr || urlParams.get("hr"); // 왠지 모르겠지만 하이라이트릴 설정이 안됨.
    params.play = params.play || urlParams.get("play") || "0";
    
    params.qs = params.qs || urlParams.get("qs") || "1";
    params.sr = params.sr || urlParams.get("sr");
    params.ss = params.ss || urlParams.get("ss");
    params.map = params.map || urlParams.get("map") || "0";
    params.maprotation = urlParams.get("maprotation") || "0";
    params.search = urlParams.get("search") || "1";
    //params.sw = "0";
    //params.mt = "0";

    params.logo = urlParams.get("logo") || "0";
    //params.isFloorDistinction = "0" || urlParams.get("isFloorDistinction");
    isFloorDistinction = urlParams.get("isFloorDistinction");
    maprotation = urlParams.get("maprotation");
    // ensure applicationKey is inserted into the bundle query string
    params.applicationKey = params.applicationKey || sdkKey;

    params.filterValue = params.filterValue || "1";
    this.applicationKey = params.applicationKey;

    params.help = urlParams.get("help") || "0";

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    //this.src = `./bundle/showcase.html?${queryString}`; // 얘가 공유 주소값 컨트롤 인듯?... 나중에 테스트 요망
    this.src = `./bundle/showcase.html?${queryString}`;
    //this.src = `../../../../bundle/showcase.html?${queryString}`; // 얘가 공유 주소값 컨트롤 인듯?... 나중에 테스트 요망  // 얘 위치를  밖으로 옮겨야 할듯?
    // console.log("*************src : "+this.src);
    //console.log("queryString : " + queryString);
    this.handleListSelection = this.handleListSelection.bind(this);

    this.modelKey = params.m;

    // save load log
    this.loadModel(this.modelKey);
    // var BGMImage = document.getElementById("audio - player");
    // BGMImage.addEventListener('click',function(){
    //   console.log("이미지 클릭");
    // })

    //const videoElements = document.querySelectorAll('video');
    //console.log("videoElements : ");
    //console.log(videoElements);
    //    console.log(this.scene.nodes);
  }
  async logoImage(thisSdk: any) {
    var modelData = await thisSdk.Model.getData();
    var logoSrcImage = document.createElement("img");
    var LogoName =
      "https://vrmuseumstorage.blob.core.windows.net/vrmuseumblob1/VRMuseumLogo/" +
      modelData.sid.toString() +
      ".png";
    console.log(LogoName);
    var request = new XMLHttpRequest();
    request.open("GET", LogoName, true);
    request.send();
    request.onload = function () {
      if (request.status == 200) {
        //console.log("MapImage exist");
        logoSrcImage.src = LogoName;

        //$(mainText).append(muteText);
      } else {
        //unMuteBGMElement.setAttribute("hidden", "true");
        console.log("LogoImage doesn't exist");
      }
    };

    // logoSrcImage.src =
    //   "https://vrmuseumstorage.blob.core.windows.net/vrmuseumblob1/230814_%EC%A0%9C%EC%A3%BC_%EC%A3%BC%EC%A0%95%EA%B3%B5%EC%9E%A5/logo.png";
    var LogoContainer = document.getElementById("logo");
    LogoContainer.appendChild(logoSrcImage);
    //logoSrcImage.style.padding = "13px 16px 15px 18px";
    //console.log("logoSrcImage.style.scale : " + logoSrcImage.style.scale);

    logoSrcImage.addEventListener("click", function () {
      // 이전 페이지로 돌아가는 기능을 실행합니다.
      //window.history.back();
      window.open("https://www.jeju.go.kr/is/onlinejeju43", "_blank");
    });
  }

  async pointerTest(thisSdk: any) {
    thisSdk.Pointer.intersection.subscribe(function (intersectionData: any) {
      // Changes to the intersection data have occurred.
      //console.log('Intersection position:', intersectionData.position);
      //console.log('Intersection normal:', intersectionData.normal);
    });
  }

  async setupSdkTest(thisSdk: any) {
    // setupSdk에 기본 model 정보를 넣어줌   setupSdk 와 getSdk의  역할이 같음
    // const mpSdk = await setupSdk(sdkKey, {
    //   space: "DePDvbVCdGj",
    //   container: "#main",
    //   iframeQueryParams: {
    //     newtags: 1,
    //     useLegacyIds: 0,
    //   },
    // });
    console.log("2");
    let lastSweep: any = null;
    var modelData = await thisSdk.Model.getData();

    // Get Labels
    var data = await getSweepLabels();

    // Create arrays - Sweep ID -> Label and Label -> ID
    var sweepLabels = new Array();
    var sweepIds: any = {};
    var sweepLegacyIds: any = {};
    console.log("2.1");
    Object.entries(data.data.model.panoLocations).forEach((entry: any) => {
      // # -> Legacy ID
      sweepLabels[entry[1].label] = entry[1].id;
      // Legacy Id -> Label
      sweepIds[entry[1].id] = entry[1].label;

      // Get the 'Legacy ID' from the Skybox Imagery filename
      let lid = entry[1].skybox.urlTemplate;
      lid = lid.split("/2k/~/");
      lid = lid[1].split("_skybox");

      // Legacy Id -> SDK Id
      sweepLegacyIds[entry[1].id] = lid[0];
      //console.log(sweepLegacyIds);
    });
    //console.log("Sweep IDs", sweepIds["b2zki7kp2a2ft670mr1htpg7c"]);// sweepIds를 알면 각 스캔으로 이동할 수 있음 , 현재의 sweeIds를 콘솔로 표시
    //console.log("Sweep Labels:", sweepLabels);
    //console.log("Sweep Legacy IDs:", sweepLegacyIds);
  console.log("2.2");
    thisSdk.Sweep.current.subscribe(function (currentSweep: any) {
      // Change to the current sweep has occurred. 지금 내가 어디있는지 표시
      //console.log(currentSweep)
      console.log("2.3");
      if (currentSweep.id !== "") {
        if (lastSweep !== null) {
          //document.getElementById("p" + lastSweep).classList.remove("active");
        }
        lastSweep = currentSweep.id;
        //console.log("currentSweep X :" + currentSweep.position.x);
        //console.log("currentSweep Z : " + currentSweep.position.z);
        // console.log(
        //   "lastSweep : " + lastSweep + " currentSweep.id : " + currentSweep.id
        // );

        // console.log(
        //   "lastSweep : " + lastSweep + " currentSweep.id : " + sweepIds[currentSweep.id]
        // );
        //console.log(sweepIds, sweepLabels, sweepLegacyIds)

        // document.getElementById("p" + currentSweep.id).classList.add("active");

        // console.log(
        //   "Entering Sweep - API ID:" +
        //     currentSweep.id +
        //     " (#" +
        //     sweepIds[currentSweep.id] +
        //     ") - Legacy SDK ID: " +
        //     sweepLegacyIds[currentSweep.id]
        // );
      }
    });
console.log("2.4");
    async function getSweepLabels() {
      // GraphQL query will fail to get the floorplan assets without a Bearer Token added to the authorization, but will return the panoLocations
      var query = `{
		  model(id: "${modelData.sid}") {
		  panoLocations {
			id
			label
			skybox(resolution: "2k") {
			  filename
			  urlTemplate
			}
		  }
		}
	  }`;
      var headers = {
        "Content-Type": "application/graphql",
        "x-matterport-application-key": sdkKey,
      };
      var options = {
        method: "POST",
        mode: "cors",
        headers: headers,
        body: query,
        redirect: "follow",
      } as RequestInit;
      var url = "https://my.matterport.com/api/mp/models/graph";
      const response = await fetch(url, options);
      //const sweeps =
      return await response.json();
    }
console.log("2.5.1");
    // Minimap
    // Get Minimap Data 미니맵 데이터 겟
    var minimapData = await getMiniMapAssets(modelData.sid); // 여기안으로 
    //console.log("Mini Map", minimapData);
console.log("2.5.2");
    // Render Minimap // 미니맵 렌더
    var mapContainer = document.getElementById("map");
console.log("2.5.3");
    var minimap = document.createElement("img");
    minimap.src = minimapData.urlTemplate;
    // console.log()
console.log("2.5.4");
    // const urltmp = window.location.href;
    // const urlParams = url.searchParams;
    // var url = new URL(urltmp);
    var MapName =
      "https://vrmuseumstorage.blob.core.windows.net/vrmuseumblob1/test/mapTest/" +
      modelData.sid.toString() +
      ".png";
    console.log("3", MapName);
    var request = new XMLHttpRequest();
    request.open("GET", MapName, true);
    request.send();

    request.onload = function () {
      console.log(request.status);
      if (request.status == 200) {
        console.log("MapImage exist");
        minimap.src = MapName;

        //$(mainText).append(muteText);
      } else {
        //unMuteBGMElement.setAttribute("hidden", "true");
        console.log("MapImage doesn't exist");
      }
    };

    mapContainer.appendChild(minimap); // 맵 추가
    console.log("4");
    // var currentButtonSweep2 = document.getElementsByClassName("frame");
    // var testtesttest = document.getElementsByTagName("button");

    // var testtesttest2 = document.getElementById(
    //   "pd67a8f075f6f4a72a733b7d5fca73189"
    // );

    //pd67a8f075f6f4a72a733b7d5fca73189

    // console.log("mapContainer : " + mapContainer);
    // console.log("currentButtonSweep2 : " + currentButtonSweep2);
    // console.log(testtesttest);
    // console.log(testtesttest2);

    thisSdk.Sweep.current.subscribe(function (currentSweep: any) {
      // Change to the current sweep has occurred. 지금 내가 어디있는지 표시
      //console.log(currentSweep)
      if (currentSweep.id !== "") {
        if (lastSweep !== null) {
          //document.getElementById("p" + lastSweep).classList.remove("active");
        }
        lastSweep = currentSweep.id;

        let px =
          ((currentSweep.position.x - minimapData.image_origin_x) *
            minimapData.resolution_ppm) /
          minimapData.width;

        let py =
          ((currentSweep.position.z * -1 - minimapData.image_origin_y) *
            minimapData.resolution_ppm) /
          minimapData.height;
        // console.log(
        //   "minimapData.resolution_ppm : "+minimapData.resolution_ppm+
        //   " currentSweep.position.x : "+currentSweep.position.x +
        //   " minimapData.image_origin_x : " +
        //     minimapData.image_origin_x +
        //     " px :" +
        //     px +
        //     " minimapData.width : " +
        //     minimapData.width
        // );
        // console.log(
        //   "currentSweep.position.y: " +
        //     currentSweep.position.y +
        //     " minimapData.image_origin_y : " +
        //     minimapData.image_origin_y +
        //     " py :" +
        //     py +
        //     " minimapData.height : " +
        //     minimapData.height
        // );
        //let svgSizeNumber = 68 / minimapData.width;
        let imageSizeNumber = minimapData.width / minimapData.height;

        // const windowWidthSize = window.innerWidth;
        // const windowHeightSize = window.innerHeight;
        //console.log("windowWidthSize : " + windowWidthSize);
        //console.log("windowHeightSize : " + windowHeightSize);

        //console.log("px1 : " + px);
        px -= 0.0385; //(px * svgSizeNumber);
        //console.log("px2 : " + px);
        py -= 0.0375 * imageSizeNumber; //px * svgSizeNumber * imageSizeNumber;
        var path = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        //var recentlySweep =0;
        if (svgRadar == null) {
          //var buttonTest = document.querySelectorAll("button");
          //console.log(buttonTest[0]);
          //console.log(currentSweep.sid);
          // 이게 현재 current button 위치의 sweep으로 이동해야함. 근데 remove하고, append했을때 애니메이션이 정상적으로 돌것인가? 안되면 삭제하고 새로 만들어야할듯, 빼거나
          //여기가 currentsweep 안이기 때문에 current로 값을 불러오면될듯?
          //value 값으로 찾아들어가야할듯

          //console.log(buttonTest.)

          // for (const buttonCheck of buttonTest) {
          //   if (buttonCheck.getAttribute("value") == currentSweep.sid) {
          //     console.log(buttonCheck);
          //   }
          // }

          svgRadar = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
          );

          svgRadar.style.left = px * 97.5 + "%";
          svgRadar.style.bottom = py * 97.5 + "%";
          // svgRadar.style.left = 0 + "px";
          // svgRadar.style.bottom = 0+"px";
          svgRadar.style.position = "absolute";

          thisSdk.Camera.pose.subscribe(function (pose: any) {
            svgRadar.style.rotate =
              -1 * (pose.rotation.y + Number(maprotation)) + "deg";
          });

          // svgRadar.style.rotate =
          //   -1 * (Number(maprotation)) + "deg";
          // console.log("svgRadar.style.left :" + svgRadar.style.left);
          // console.log("svgRadar.style.bottom : " + svgRadar.style.bottom);

          svgRadar.setAttribute("width", "68"); // 퍼센트로
          svgRadar.setAttribute("height", "68");

          //svg.setAttribute("position", "absolute");

          // 경로 요소 생성

          path.setAttribute("stroke", "rgb(224,224,160)");
          path.setAttribute("stroke-width", "1");
          path.setAttribute("stroke-opacity", "0.5");
          path.setAttribute("fill", "rgb(127,95,63)");
          path.setAttribute("fill-opacity", "0.5");
          //path.setAttribute("d", "M 33.5,33.5 L 23.5,5 A 32,32 0 0 1 43.5,5 Z");
          path.setAttribute("d", "M 33.5,33.5 L 10,10 A 32,32 0 0 1 57,10 Z");
          path.style.transition = "d 0.5s";
          // 경로 요소를 SVG에 추가
          svgRadar.appendChild(path);

          // SVG를 mapContainer 요소에 추가
          mapContainer.appendChild(svgRadar);
          // console.log("currentSweep.id :" + currentSweep.id);
          // console.log("currentSweep.value :" + currentSweep.value);
          // var currentButtonSweep = document.querySelector("button");

          // console.log(currentButtonSweep, "p" + currentSweep.id);
          //console.log(currentButtonSweep.item+"");

          //currentButtonSweep.appendChild(svgRadar);

          // buttonTest.forEach((button) => { // 현재 cureent sweep에 svg추가
          //   if (button.getAttribute("value") === currentSweep.sid) {
          //     console.log(button);
          //     button.appendChild(svgRadar);
          //   }
          // });

          //recentlySweep = currentSweep.sid;
        } else {
          // radar가 있을때 현재 radar와 과거 radar가 다를 때, 작아졌다가, 제거, 현재 currentsweep에 추가 후, 애니메이션 동작
          // if (recentlySweep != currentSweep.sid) {

          //   var currentSVGPath = document.querySelector("path");

          //   currentSVGPath.setAttribute(
          //     "d",
          //     "M 33.5,33.5 L 33.5,0 A 32,32 0 0 1 33.5,0 Z"
          //   );

          //   //setTimeout(MoveRadar, 350);
          //   //과거 sweepbutton에서 radar제거 새로만들어야하나? 삭제하기전에 복사(추가)를 하고 삭제를 하는게
          //   setTimeout(() => {
          //     MoveRadar(recentlySweep, currentSweep.sid);
          //   }, 350);

          //   //현재 sweepbutton에서 radar추가
          //   //setTimeout(resetRadar, 700);
          //   //  setTimeout(() => {
          //   //    resetRadar(currentSweep.sid);
          //   //  }, 700);

          //   recentlySweep = currentSweep.sid;
          // }
          var currentSVGPath = document.querySelector("path");
          currentSVGPath.setAttribute(
            "d",
            "M 33.5,33.5 L 33.5,0 A 32,32 0 0 1 33.5,0 Z"
          );

          setTimeout(MoveRadar, 350);

          setTimeout(resetRadar, 700);
        }
        function MoveRadar() {
          currentSVGPath.style.opacity = "0";
          svgRadar.style.left = px * 97.5 + "%";
          svgRadar.style.bottom = py * 97.5 + "%";
        }
        function resetRadar() {
          currentSVGPath.style.opacity = "1";
          currentSVGPath.setAttribute(
            "d",
            "M 33.5,33.5 L 10,10 A 32,32 0 0 1 57,10 Z"
          );
        }
        // function MoveRadar(recentlySweep :any, currentSweep:any) {
        //   // 기존 스윕에서 새로운 스윕으로 radar를 추가하고, 기존 스윕에서 radar를 삭제

        //   // queryselector로 일단 radar를 찾아, 그리고, 현재 button에 추가하고, 과거 button을 찾아서 radar를 삭제,
        //      buttonTest.forEach((button) => {
        //        // 현재 cureent sweep에 svg추가
        //        if (button.getAttribute("value") === currentSweep) {
        //          console.log(button);
        //          button.appendChild(svgRadar);
        //        }

        //      });
        //       buttonTest.forEach((button) => {
        //         // 현재 cureent sweep에 svg추가

        //         if (button.getAttribute("value") === recentlySweep) {
        //           console.log(button);
        //           button.removeChild(svgRadar);
        //         }
        //       });

        //   //var recentlySweepFind = document.querySelector(recentlySweep); // 과거 스윕 찾기,
        //   //var currentSweepFind = document.querySelector(currentSweep); // 현재 스윕 찾기,
        //   //recentlySweepFind.removeChild(recentlySweep); // 삭제
        //   //currentSVGPath.style.opacity = "0";

        //   //svgRadar.style.left = px * 97.5 + "%";
        //   //svgRadar.style.bottom = py * 97.5 + "%";
        // }
        // function resetRadar(currentSweep : any) {
        //   var currentSweepFind = document.querySelector(currentSweep);
        //   currentSweepFind.removeChild(currentSweep); // 삭제
        //   currentSVGPath.style.opacity = "1";
        //   currentSVGPath.setAttribute(
        //     "d",
        //     "M 33.5,33.5 L 10,10 A 32,32 0 0 1 57,10 Z"
        //   );
        // }
        //svgRadar.style.rotate = "0";
      }
    });

    // 이안에서 svg radar의 위칫값을 지정하고, init 스윕 id를 알수잇으면 될듯?
    thisSdk.Sweep.data.subscribe({
      onAdded: function (index: any, item: any, collection: any) {
        if (item.alignmentType == "aligned") {
          let thisSweep = document.createElement("button");
          let px =
            ((item.position.x - minimapData.image_origin_x) *
              minimapData.resolution_ppm) /
            minimapData.width;
          thisSweep.style.left = px * 97.5 + "%";
          let py =
            ((item.position.z * -1 - minimapData.image_origin_y) *
              minimapData.resolution_ppm) /
            minimapData.height;
          thisSweep.style.bottom = py * 97.5 + "%";
          thisSweep.setAttribute("id", "p" + index);
          thisSweep.setAttribute("value", index);
          thisSweep.setAttribute("floor", item.floorInfo.sequence);
          thisSweep.addEventListener("click", sweepMove);
          thisSweep.textContent = sweepIds[index];

          //console.log(sweepIds[index]);
          // console.log(
          //   "sweep added to the collection",
          //   index,
          //   item.floorInfo.sequence,
          //   collection
          // );
          //console.log(collection);

          mapContainer.appendChild(thisSweep);
          // 여기에서 radar를 추가해야겟는데?

          thisSdk.Sweep.current.subscribe(function (currentSweep: any) {
            // Change to the current sweep has occurred.
            if (currentSweep.sid === "") {
              //console.log("Not currently stationed at a sweep position");
            } else {
              // console.log("Currently at sweep", currentSweep.sid);
              // console.log("Current position", currentSweep.position);
              //console.log("On floor", currentSweep.floorInfo.sequence);
              //mapContainer.removeChild(thisSweep);

              if (currentSweep.floorInfo.sequence == item.floorInfo.sequence) {
                //console.log(mapContainer);
              } else {
                //const removeButton = mapContainer.querySelector(".button");
                //mapContainer.removeChild(removeButton);
              }

              //console.log()

              //console.log(collection["7d62730644b5487282d2cb84dde9fead"].floorInfo);
              // for (const sweepCollection in collection) {
              //   // console.log(
              //   //   "currentSweep.floorInfo.sequence : "+
              //   //   currentSweep.floorInfo.sequence
              //   // );
              //   // console.log(
              //   //   "sweepCollection : " +
              //   //     sweepCollection
              //   // );
              //   if (
              //     currentSweep.floorInfo.sequence == collection[sweepCollection].floorInfo.sequence
              //   ) {
              //
              //   } else {
              //     console.log("이 층의 sweep의 아님 ")
              //   }
              // }
            }
          });
          //mapContainer.appendChild(thisSweep);
          //console.log("thisSdk.Sweep.data.subscribe onAdded");
        }
      },
      onCollectionUpdated: function (collection: any) {
        //console.log("the entire up-to-date collection", collection);
      },
    });

    var recentlyFloor = 0;
    thisSdk.Floor.current.subscribe(function (currentFloor: any) {
      // Change to the current floor has occurred.
      if (currentFloor.sequence === -1) {
        //console.log("Currently viewing all floors");
      } else if (currentFloor.sequence === undefined) {
        if (currentFloor.id === undefined) {
          //console.log("Current viewing an unplaced unaligned sweep");
        } else {
          //console.log("Currently transitioning between floors");
        }
      } else {
        // console.log("Currently on floor", currentFloor.id);
        // console.log("Current floor's sequence index", currentFloor.sequence); // 지상 1 , 지하 0
        // console.log("Current floor's name", currentFloor.name);
        if (recentlyFloor != currentFloor.sequence) {
          //층수가 달라 졌다면
          //console.log("층이달라짐!");
          // 여기서 지금 찾지를 못함  // 버튼이 로드되기 전에 찾아서 그런듯? null일때는 넘어가고, exist할때만 동작하는걸로,
          // 버튼 태그 자체는 찾는게 가능함, 찾은 버튼들을 층이동을할때만 같은 층수의 버튼만 활성화하고, 다른층은 비활성화, <= 이건 찾아야함
          // 그리고, 같은 층수의 mapimage 태그도 찾아서, 해당층수의 image로 변경 하는 기능 추가,
          if (isFloorDistinction == 1) {
            // 층계기능 파라미터 추가,  isFloorDistinction = 0
            //var mapImage = document.querySelector("map");
            var mapImageUrl = document.querySelector("img");
            var buttonTest = document.querySelectorAll("button");
            //console.log(buttonTest[0]);
            buttonTest.forEach((button: any) => {
              const floorValue: string = button.getAttribute("floor");
              // button.querySelector(".floor")?.textContent || "";

              if (floorValue == currentFloor.sequence) {
                button.disabled = false; // 버튼 활성화
                button.style.opacity = "0.5";
                //button.style.display = "inline-block";
                //button.hover.style.display = "inline-block";
                //mapImageUrl.getAttribute("src")
                mapImageUrl.src =
                  "https://vrmuseumstorage.blob.core.windows.net/vrmuseumblob1/test/mapTest/ByMaSeN1Rfi_F" +
                  currentFloor.sequence +
                  ".png";

                // if (currentFloor.sequence == 1) { // 모델명 + 층수로 읽어오도록 (ex : 모델sid + 층수 (F0, F1, F2 ~))
                //   mapImageUrl.src =
                //     "https://vrmuseumstorage.blob.core.windows.net/vrmuseumblob1/230814_%EC%A0%9C%EC%A3%BC_%EC%A3%BC%EC%A0%95%EA%B3%B5%EC%9E%A5/%EB%A7%B5%EC%9D%B4%EB%AF%B8%EC%A7%80/vr_colorplan_000_F1.png";

                // } else {
                //   mapImageUrl.src =
                //     "https://vrmuseumstorage.blob.core.windows.net/vrmuseumblob1/230814_%EC%A0%9C%EC%A3%BC_%EC%A3%BC%EC%A0%95%EA%B3%B5%EC%9E%A5/%EB%A7%B5%EC%9D%B4%EB%AF%B8%EC%A7%80/vr_colorplan_000_B1.png";
                // }
                //console.log("if buttonfloor : " + floorValue);
              } else {
                button.disabled = true; // 버튼 비활성화
                //button.style.display = "none";
                button.style.opacity = "0";
                // console.log("else buttonfloor : " + floorValue);
              }
            });
          }
          //console.log(buttons);
        }
        recentlyFloor = currentFloor.sequence;
      }
    });

    // On click - move
    function sweepMove(event: any) {
      // 여기서 이동을 할때, 레이더이미지를 함께 타겟 위치로 이동시키고,
      // 일단 레이더 이미지를 create 해야함, 그리고   ansy 함수로 카메라 rotation을 불러와서  카메라  회전 이벤트가 발생할때, 이미지도 같이 회전
      console.log("event.target.name : " + event.target.name);
      console.log("event.target.id : " + event.target.id);
      console.log("event.target.value : " + event.target.value);
      //console.log(event.target);

      return thisSdk.Sweep.moveTo(event.target.value, {
        transition: thisSdk.Sweep.Transition.FLY,
      });
    }

    // Display Minimap
    await thisSdk.App.state.waitUntil(
      (appState: any) => appState.phase == "appphase.playing"
    );
    document.body.classList.add("started");

    // Legacy Method for getting Minimap Assets from unpublished REST API endpoints (2023 Sunset TBD)
    async function getMiniMapAssets(sid: any) {
      var headers = {
        "Content-Type": "application/graphql",
        "x-matterport-application-key": sdkKey,
      };
      var options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      } as RequestInit;
      var url =
        "https://my.matterport.com/api/player/models/" + sid + "/files?type=3";
      console.log("getMiniMapAssets 5");
      const response = await fetch(url, options);
      console.log("2.5.1.1");
      const responseJson = await response.json();
      console.log("2.5.1.2");
      // const catalogUrl = responseJson.templates[0].replace(
      //   "{{filename}}",
      //   "catalog.json"
      // );
      //const catalogData = await fetch(catalogUrl, options);
      //const catalogFiles = await catalogData.json();
      //console.log(catalogFiles);
      console.log("2.5.1.3");

      const colorplanData = responseJson.templates[0].replace(
        "{{filename}}",
        "render/vr_colorplan.json"
      );
      console.log(colorplanData, "2.5.1.3.1"); // 여기까지 되고
      //colorplanData.retry = true;
      console.log(options, "2.5.1.3.1"); // 여기까지 되고
      var miniMapDataResponse = await fetch(colorplanData, options);
      console.log("2.5.1.4");
      let miniMapData = await miniMapDataResponse.json();
      console.log("2.5.1.5");
      miniMapData.urlTemplate = responseJson.templates[0].replace(
        "{{filename}}",
        "render/vr_colorplan_000.png"
      );
      // console.log("catalogUrl : " + catalogUrl);
      // console.log("colorplanData : " + colorplanData);
      // console.log("miniMapData.urlTemplate : " + miniMapData.urlTemplate);
      return miniMapData;
    }
  }

  async assetsFloorPlanTest() {
    console.log("floorplan");
    if (await this.sdk.model.assets.floorplan.load()) {
      console.log("floorplan.url : " + this.sdk.model.assets.floorplan.url);
    }
  }

  async componentDidMount() {
    // ??
    this.sdk = await GetSDK("sdk-iframe", this.applicationKey);

    await this.spotClickEvent(this.modelKey);
    await this.tagClickEvent(this.modelKey);
    await this.playClickEvent(this.modelKey);
    await this.FloorStateEvent(this.modelKey);

    await this.VideoClickEvent(this.modelKey);
    await this.cameraMoveEvent(this.modelKey);
    await initComponents(this.sdk);
    await this.createCameraControl(this.sdk);
    await this.sdk.Scene.configure((renderer: any, three: any) => {
      ``;
      renderer.physicallyCorrectLights = true;
      renderer.gammaFactor = 2.2;
      renderer.gammaOutput = true;
      
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.bias = 0.0001;
      renderer.shadowMap.type = three.PCFSoftShadowMap;
    });

    //console.log(this.sdk.Scene.renderer);

    var url;
    const urltmp = window.location.href;
    url = new URL(urltmp);

    const urlParams = url.searchParams;
    //console.log("map : "+urlParams.get("map"));
    //console.log(this.isMap);
    await this.pointerTest(this.sdk);
    if (urlParams.get("map") == "1" && isMap) {
      await this.setupSdkTest(this.sdk);
      console.log("1");
    }
    //isLoga
    if (urlParams.get("logo") == "1") {
      await this.logoImage(this.sdk);
    }

    this.scene = new SceneLoader(this.sdk);

    const slots: SlotNode[] = [];

    class VideoClickSpy implements IComponentEventSpy<IPlaneRenderer> {
      public eventType = ComponentInteractionType.CLICK; // 이벤트 타입 지정
      constructor(private mainComponent: Main) {}
      onEvent(payload: any) {
        this.mainComponent.videoMuteControlInteraction(
          payload.node,
          payload.component,
          payload.type
        );
        //console.log(payload.component)
        console.log("currentUnMutedNode :",currentUnMutedNode);
        
        //console.log(this.mainComponent.scene.nodes.length);
        //여기서 for문 돌려서 처리하면 될듯? 
        //this.payload
      }
    }
  var currentUnMutedNode :any= null;
    class ClickSpy implements IComponentEventSpy<IInteractionEvent> {
      public eventType = ComponentInteractionType.CLICK;
      constructor(private mainComponent: Main) {}
      onEvent(payload: IInteractionEvent) {
        this.mainComponent.handleOrientedBoxInteraction(
          payload.node,
          payload.component,
          payload.type
        );
        console.log("clickclickclick");
      }
    }

    // class HoverSpy implements IComponentEventSpy {
    //   public eventType = ComponentInteractionType.HOVER;
    //   constructor(private mainComponent: Main) {}
    //   onEvent(payload: { hover: boolean }) {
    //     this.mainComponent.cameraInput.inputs.suppressClick = !payload.hover;
    //   }
    // }

    const videoClickSpy = new VideoClickSpy(this);
    const clickSpy = new ClickSpy(this);

    //const hoverSpy = new HoverSpy(this);

    //this.sdk.on()
    //console.log(this.scene)

    //console.log(await this.sdk.Scene.registerComponents);

    const findSlots = (node: ISceneNode) => {
      let slot: SceneComponent = null;
      let model: SceneComponent = null;
      let box: OrientedBox = null;
      let plane: IPlaneRenderer = null;
      const componentIterator: IterableIterator<SceneComponent> =
        node.componentIterator();
      for (const component of componentIterator) {
       // component.spyOnEvent(videoClickSpy);
        if (component.componentType === slotType) {
          slot = component;
          //slot.spyOnEvent(videoClickSpy);
        } else if (component.componentType === "mp.gltfLoader") {
          model = component;
        } else if (component.componentType == orientedBoxType) {
          box = component as OrientedBox;
          box.spyOnEvent(clickSpy);
          //box.spyOnEvent(hoverSpy);
          //box.spyOnEvent(videoClickSpy);
          box.inputs.color = UnselectedColor;
          box.inputs.opacity = UnselectedOpacity;
        } else if (component.componentType == "mp.planeRenderer1") {
          plane = component as IPlaneRenderer;
          plane.spyOnEvent(videoClickSpy);
          //console.log(plane)
        } else if (component.componentType == "mp.toggleState1") {
          //console.log("findSlots");
          // console.log(component.componentType
          //   ); // 여기서 생성됨! 도네

          component.spyOnEvent(videoClickSpy);
          // 이건 스파이 기능이고, 이벤트가 발생했을때를 감지 할수있는지와, 역으로 main에서 코드로 이벤트를 줄수있는지 확인,

          // box.spyOnEvent(videoClickSpy);
          // console.log("boxboxbox~");
        } else {
          //component.spyOnEvent(clickSpy);
          //console.log(component.onEvent)
        }
      }

      if (slot && model) {
        slots.push({
          node: node,
          slotComponent: slot,
          modelComponent: model,
          boxComponent: box,
        });
      }
    };

    this.slots = slots;

    await this.scene.load("AAWs9eZ9ip6", findSlots);

    //console.log(this.scene.nodes);
    /* 설계 일단 노트 전체가 nodes에 배열화 되어 있음, 한개의 nodes에서 videoCilckspy로 plane에 이벤트가 발생했음을 감지하고,
    plane의 outputs에 false 값을 전달,
    추가적으로 구현할 기능
    1. 현재 목표 : 한개의 비디오 켜졌을때 나머지 오디오 오프
    2. sceneload에서 bgm 오브젝트 추가 등록하고, bgm오브제를 nodes 배열에 push추가해서 같이 관리 <= 이거하려면 bgm mute의 형식을 video mute와 같에 맞추어야함
    3. 특정 스캔지점에 도달했을때 영상을 틀거나, 소리를 켜는 기능 
    4. 별도기능 : 오디오와, 유저(카메라)의 거리를 측정해서 비디오가 자동으로 켜지게 하는 기능 (모바일 환경에는 거리가 가까울때만 틀도록)
    5. 
    */

    //이벤트를 긁어와서 여기서 처리해야함.
    // plane에서 onevent? clickevent를 감지하고, 이를
    // node spy로 이벤트를 감지한다. -> 전체 node를 for문을 돌려 음소거를 진행한다. 
    // nodes의 길이만큼 for문으로 muted 를 실행, 
    // 소리가 켜져있는지 검사하고, 이벤트가 발생했을때 소리가 켜져 있는 오브젝트만 음소거, 
    //console.log("test");
    for (var i =0; i< this.scene.nodes.length ;i++) 
    {
      
      const componentIterator: IterableIterator<SceneComponent> =
        this.scene.nodes[i].componentIterator();
      for (const node of componentIterator) {
        //continue
        if (node.componentType == "mp.planeRenderer") {
          
          //node.spyOnEvent(videoClickSpy);
          //console.log(node.events);
          currentUnMutedNode = i;
          node.spyOnEvent(videoClickSpy);// 여기서 그전에 켜져있던 소리를 비활성화
          
          //console.log("123123");
          //node.outputs.state = false;
          //node.outputs.state
        }
      }
      
    }
    
    //console.log(this.sdk.SceneLoader);

    //console.log(this.scene.nodes[0]);

    //this.scene.nodes[0].add

    //console.log(this.sdk);
    
  }

  private handleListSelection(item: ItemDesc) {
    const slotNode = this.state.slotNode;
    if (!slotNode) {
      return;
    }
    slotNode.slotComponent.inputs.model = item.url;
    slotNode.modelComponent.inputs.Position.x = item.position.x;
    slotNode.modelComponent.inputs.Position.y = item.position.y;
    slotNode.modelComponent.inputs.Position.z = item.position.z;
    slotNode.modelComponent.inputs.localRotation.x = item.rotation.x;
    slotNode.modelComponent.inputs.localRotation.y = item.rotation.y;
    slotNode.modelComponent.inputs.localRotation.z = item.rotation.z;
    slotNode.modelComponent.inputs.localScale.x = item.scale.x;
    slotNode.modelComponent.inputs.localScale.y = item.scale.y;
    slotNode.modelComponent.inputs.localScale.z = item.scale.z;
  }

  //이게 컨트롤하는 거네, 이거 이용해서 만들면될듯?
  private handleOrientedBoxInteraction(
    node: ISceneNode,
    component: SceneComponent,
    interactionType: ComponentInteractionType
  ) {
    if (interactionType === ComponentInteractionType.CLICK) {
      // select this node
      console.log(node);
      for (const slot of this.slots) {
        if (slot.node === node) {
          for (const componentSearch of node.componentIterator()) {
            if (componentSearch === component) {
              const lastSlotNode = this.state.slotNode;
              if (lastSlotNode) {
                lastSlotNode.boxComponent.inputs.color = UnselectedColor;
                lastSlotNode.boxComponent.inputs.opacity = UnselectedOpacity;
                lastSlotNode.boxComponent.inputs.lineOpacity =
                  UnselectedLineOpacity;
              }

              if (lastSlotNode === slot) {
                this.cameraInput.inputs.focus = null;

                this.setState({
                  slotNode: null,
                });
              } else {
                this.setState({
                  slotNode: slot,
                });

                slot.boxComponent.inputs.color = SelectedColor;
                slot.boxComponent.inputs.opacity = SelectedOpacity;
                slot.boxComponent.inputs.lineOpacity = SelectedLineOpacity;
                this.cameraInput.inputs.focus = node.position;
                //console.log(this.cameraInput.inputs);
              }
            }
          }
        }
      }
    }
  }

  private videoMuteControlInteraction(
    node: ISceneNode,
    component: SceneComponent,
    interactionType: ComponentInteractionType
  ) {
    if (interactionType === ComponentInteractionType.CLICK) {
      // select this node
      console.log(node);
      for (const slot of this.slots) {
        if (slot.node === node) {
          for (const componentSearch of node.componentIterator()) {
            if (componentSearch === component) {
              const lastSlotNode = this.state.slotNode;
              if (lastSlotNode) {
                lastSlotNode.boxComponent.inputs.color = UnselectedColor;
                lastSlotNode.boxComponent.inputs.opacity = UnselectedOpacity;
                lastSlotNode.boxComponent.inputs.lineOpacity =
                  UnselectedLineOpacity;
              }

              if (lastSlotNode === slot) {
                this.cameraInput.inputs.focus = null;

                this.setState({
                  slotNode: null,
                });
              } else {
                this.setState({
                  slotNode: slot,
                });

                slot.boxComponent.inputs.color = SelectedColor;
                slot.boxComponent.inputs.opacity = SelectedOpacity;
                slot.boxComponent.inputs.lineOpacity = SelectedLineOpacity;
                this.cameraInput.inputs.focus = node.position;
                //console.log(this.cameraInput.inputs);
              }
            }
          }
        }
      }
    }
  }
  // private drawInteraction(
  //   payloadInputs: IInteractionEvent,
  //   interactionType: ComponentInteractionType
  // ) {
  //   if (interactionType === ComponentInteractionType.CLICK) {

  //   }
  // }

  render() {
    let filteredItems: ItemDesc[] = [];
    const { slotNode } = this.state;

    if (slotNode) {
      const { items, slots } = this.props.appState;
      const category = slots.get(slotNode.node.name);

      if (category) {
        filteredItems = items.get(category);
      }
    }
    const urltmp = window.location.href;
    var url;
    url = new URL(urltmp);
    const urlParams = url.searchParams;
    urlParams.get("filterValue");
    return (
      <div className="main" style={{filter:"brightness("+ urlParams.get("filterValue")+")"}}>
        <ItemList
          items={filteredItems}
          onSelected={this.handleListSelection}
        ></ItemList>
        <div style={{ position: "absolute" }} id="map"></div>
        <div style={{ position: "absolute" }} id="logo"></div>
        <Frame src={this.src} ></Frame>
      </div>
    );
  }

  async createCameraControl(theSdk: any) {
    const cameraNode = await theSdk.Scene.createNode();
    const cameraPose = await theSdk.Camera.getPose();
    this.cameraInput = cameraNode.addComponent(cameraInputType);
    // convert sdk pose to THREE.js objects
    this.cameraInput.inputs.startPose = {
      position: new Vector3(
        cameraPose.position.x,
        cameraPose.position.y,
        cameraPose.position.z
      ),
      quaternion: new Quaternion().setFromEuler(
        new Euler(
          (cameraPose.rotation.x * Math.PI) / 180,
          (cameraPose.rotation.y * Math.PI) / 180,
          ((cameraPose.rotation.z || 0) * Math.PI) / 180,
          "YXZ"
        )
      ),
      projection: new Matrix4().fromArray(cameraPose.projection).transpose(),
    };
    //console.log(this.cameraInput.inputs)
    const cameraControl = cameraNode.addComponent("mp.camera");
    cameraControl.bind("camera", this.cameraInput, "camera");

    cameraNode.start();
  }

  async spotClickEvent(modelKey: string) {
    this.sdk.on(
      this.sdk.Sweep.Event.ENTER,
      function (oldSweep: string, newSweep: string) {
        //console.log(oldSweep, newSweep);
        if (oldSweep && oldSweep !== newSweep) {
          const url: string = `https://embed.360vrmuseum.com/api/spot/${modelKey}/${newSweep}`;

          var xhr = new XMLHttpRequest();
          xhr.open("POST", url, true);

          xhr.onload = function (e) {
            //console.log("spot success"); // 여기에서 sweep move를 확인해야 할듯?
          };

          xhr.onerror = function (e) {
            console.error(xhr.statusText);
          };

          xhr.send("");
        }
      }
    );
  }
  async cameraMoveEvent(modelKey: string) {
    this.sdk.on(this.sdk.Camera.Event.MOVE, function (pose: any) {
      // 카메라를 돌릴때   이게 저기 맵안에 들어가야함. 그리고 카메라를 돌릴때 레이더도 함께 회전.
      svgRadar.style.rotate =
        -1 * (pose.rotation.y + Number(maprotation)) + "deg";
      //  console.log(
      //    pose.rotation.y,
      //    Number(maprotation),
      //    pose.rotation.y + Number(maprotation)
      //  ); // y 축을 기준으로 돌았을때 , -180 ~ 0 ~ 180으로 움직임
    });
  }

  async tagClickEvent(modelKey: string) {
    this.sdk.on(this.sdk.Mattertag.Event.CLICK, function (sid: string) {
      const url: string = `https://embed.360vrmuseum.com/api/tag/${modelKey}/${sid}`;

      console.log("clickclickMain");
      var xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);

      xhr.onload = function (e) {
        console.log("tag success");
      };

      xhr.onerror = function (e) {
        console.error(xhr.statusText);
      };

      xhr.send("");
    });
  }
  async FloorStateEvent(modelKey: string) {
    this.sdk.Floor.current.subscribe(function (currentFloor: any) {
      // Change to the current floor has occurred.
      if (currentFloor.sequence === -1) {
        // console.log("Currently viewing all floors");
      } else if (currentFloor.sequence === undefined) {
        if (currentFloor.id === undefined) {
          // console.log("Current viewing an unplaced unaligned sweep");
        } else {
          // console.log("Currently transitioning between floors");
        }
      } else {
        // console.log("Currently on floor", currentFloor.id);
        // console.log("Current floor's sequence index", currentFloor.sequence);
        // console.log("Current floor's name", currentFloor.name);
      }
    });
  }

  async VideoClickEvent(modelKey: string) {
    this.sdk.on(
      //this.sdk.App.Event.PHASE_CHANGE,
      this.sdk.Scene.IInteractionEvent,
      function (app: string) {
        console.log("VideoClickEvent");
      }
    );

    
  }

  async playClickEvent(modelKey: string) {
    
    this.sdk.on(this.sdk.App.Event.PHASE_CHANGE, function (app: string) {
      if (app == "appphase.playing") {
        const url: string = `https://embed.360vrmuseum.com/api/play/showcase/${modelKey}`;

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);

        xhr.onload = function (e) {
          console.log("play success");
        };

        xhr.onerror = function (e) {
          console.error(xhr.statusText);
        };

        xhr.send("");
      }
    });
  
  }

  private async loadModel(modelKey: string) {
    const url: string = `https://embed.360vrmuseum.com/api/load/showcase/${modelKey}`;
    //console.log("===================");
    //console.log(modelKey);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);

    xhr.onload = function (e) {
      console.log("load success");
    };

    xhr.onerror = function (e) {
      console.error(xhr.statusText);
    };

    xhr.send("");
  }
}

// from cwf/modules/browser.ts
export const objectFromQuery = (url?: string): { [key: string]: string } => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  url = url || window.location.href;
  const object: { [param: string]: string } = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
    [matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};
